'use client';

import {
  Text,
  useColorModeValue,
  Box,
  Link,
  Image,
  Button,
  VStack, HStack
} from '@chakra-ui/react';
import NextLink from 'next/link'

import RaiaLogoPng from "../public/img/raia/Raia-Logo-Web.png"

export default function Home() {
  const gray = useColorModeValue('gray.500', 'white');
  const textColor = useColorModeValue('gray.100', 'white');

  const handleRedirect = (url: string) => {
    window.location.href = url;
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <VStack spacing={4} width="100%" >
        <Image src={RaiaLogoPng.src} alt="Raia Logo" mb={50} mt={25} maxWidth="350px"/>
        
        <Text color={textColor} textAlign="center" mb={50} maxWidth="800px" fontSize="2xl">
        Meet RAIA, a comprehensive suite of tools designed to revolutionize your interaction with AI. Powered by OpenAI models, RAIA brings GenAI capabilities to everyone, enabling you to tap into the full potential of Generative AI.
        </Text>
        <Text color={textColor} textAlign="center" mb={50} maxWidth="800px" fontSize="20">
        If you want to try RAIA, please <Link href="https://www.cloudnitio.com/contact/" color="teal.500" isExternal>contact us</Link> to schedule a demo.
        </Text>
        <HStack spacing={4} width="100%" maxWidth="350px" mb={50}>
          <Button 
            colorScheme="brandScheme" 
            bg="#0CC0DF" 
            variant="solid" 
            size="lg" 
            width="100%" 
            minW={150}
            isDisabled={true} // Disable the button
            //onClick={() => handleRedirect('https://cloudnitio.b2clogin.com/cloudnitio.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUp&client_id=07a8f376-748b-4c09-83e6-2723e2711c20&nonce=defaultNonce&redirect_uri=http://localhost:3000/api/authAPI&scope=openid&response_type=id_token&prompt=login')}
            >
            Sign Up
          </Button>
            <Button 
              colorScheme="brandScheme" 
              bg="#7F27B5" 
              variant="solid" 
              size="lg" 
              width="100%" 
              minW={150}
                      onClick={() => handleRedirect('https://cloudnitio.b2clogin.com/cloudnitio.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=07a8f376-748b-4c09-83e6-2723e2711c20&nonce=defaultNonce&redirect_uri=https://raia.cloudnitio.com/api/authAPI/callback&scope=openid&response_type=id_token&prompt=login')}
              >
              Sign In
            </Button>
        </HStack>
      </VStack>
    </Box>

  );
}